import { uuid, empty } from '/@/shared/utils'
import _cloneDeep from 'lodash.clonedeep'
import ModelStore from './store';

export default class ModelTree extends ModelStore {
  constructor(data = null, parent = null) {
    if (!data) {
      data = {}
    }

    // Certain properties should be determined by the model itself
    const { canHaveChildren, ...trimmedData} = data

    const d = {
      ...trimmedData,
      ...{ parent },
    }
    super(d)
  }

  get hasParent() {
    return this.parent !== null
  }

  get hasChildren() {
    return this.canHaveChildren && this.children.length > 0
  }

  get requiresAncestors() {
    return this.requiredAncestors !== null
  }

  get requiresPreviousSibling() {
    return this.requiredPreviousSiblings !== null
  }

  get isRoot() {
    return this.parent === null
  }

  get isCollapsed() {
    return !this.isOpen
  }

  initVariables() {
    super.initVariables()

    this._id = uuid()
    this._title = ''
    this._parent = null
    this._isActive = false
    this._isOpen = false
    this._children = []
    this._isDeletable = true
    this._isDraggable = true
    this._requiredPreviousSiblings = null
    this._requiredAncestors = null
    this._canHaveChildren = true
  }

  is(node) {
    return this.id === node.id
  }

  find(id) {
    return this.searchDown(this.getRoot(), (child) => {
      return child.id === id
    })
  }

  first(callback, node) {
    if (empty(node)) {
      node = this.getRoot()
    }

    return this.searchDown(node, callback)
  }

  includes(callback, node) {
    return this.first(callback, node) !== false
  }

  addChild(node) {
    if (!this.canNodeBeAdded(node)) {
      return
    }

    if (node.parent) {
      node.parent.removeChild(node)
    }

    node.parent = this
    this.children.push(node)
  }

  addChildAtIndex(node, index) {
    if (!this.canNodeBeAdded(node)) {
      return
    }

    if (node.parent) {
      node.parent.removeChild(node)
    }

    node.parent = this
    this.children.splice(index, 0, node)
  }

  getIndex() {
    return this.parent.children.findIndex((child) => {
      return child.id === this.id
    })
  }

  previousSiblings(node = this) {
    const nodeIndex = node.getIndex()

    return node.parent.children.filter((_, index) => {
      return index < nodeIndex
    })
  }

  removeChild(node) {
    const isActive = this.searchDown(node, (child) => {
      return child.isActive
    })

    if (isActive) {
      this.getRoot().activate()
    }

    this.children = this.children.filter((child) => child.id !== node.id)
  }

  remove() {
    this.parent.removeChild(this)
  }

  deActivate() {
    this.walk((child) => {
      child.isActive = false
    }, this.getRoot())
  }

  activate() {
    this.deActivate()
    this.isActive = true
  }

  open(activate = true) {
    this.isOpen = true

    if (activate) {
      this.activate()
    }
  }

  close() {
    this.isOpen = false
    this.deActivate()
  }

  getRoot() {
    let result = this

    while (result.parent) {
      result = result.parent
    }

    return result
  }

  getLastChild() {
    if (this.hasChildren) {
      return this.children[this.children.length - 1]
    }
  }

  getActive() {
    return this.searchDown(this.getRoot(), (child) => {
      return child.isActive
    })
  }

  all() {
    const all = []

    this.walk((child) => {
      all.push(child)
    })

    return all
  }

  descedants() {
    return this.all().filter((node) => {
      return node.id !== this.id
    })
  }

  ancestors(node = this) {
    const result = []

    while (node.parent) {
      node = node.parent
      result.push(node)
    }

    return result
  }

  walk(callback, node = this) {
    callback(node)

    if (node.children) {
      node.children.forEach((child) => {
        this.walk(callback, child)
      })
    }
  }

  filter(callback, node = this) {
    const elements = []

    node.walk((n) => {
      if (callback(n)) {
        elements.push(n)
      }
    })

    return elements
  }

  searchDown(node, callback) {
    let result = callback(node)

    if (result) {
      return node
    }

    if (!result && node.children) {
      for (let index = 0; result === false && index < node.children.length; index++) {
        result = this.searchDown(node.children[index], callback)
      }
    }

    return result
  }

  searchUp(callback, node = this) {
    let result = callback(node)

    if (result) {
      return node
    }

    while (!result && node.parent) {
      node = node.parent
      result = callback(node)
    }

    return result
  }

  canNodeBeAdded(node) {
    if (!node.parent) {
      return true
    }

    if (this.hasAncestor(node)) {
      return false
    }

    if (node.requiresPreviousSibling) {
      return this.isRequiredPreviousSiblingTypesPresent(node.requiredPreviousSiblings)
    }

    if (node.requiresAncestors) {
      return this.isRequiredAncestorTypePresent(node.requiredAncestors)
    }

    return true
  }

  hasAncestor(ancestor) {
    const result = this.searchUp((parent) => {
      return parent.id === ancestor.id
    })

    return result
  }

  isAncestorOfNode(node) {
    while (this.parent && this.parent.id !== node.id) {
      node = node.parent
    }

    return node.id !== this.id
  }

  isRequiredAncestorTypePresent(types) {
    if (types.includes(this.type)) {
      return true
    }

    const result = this.searchUp((parent) => {
      return types.includes(parent.type)
    })

    return result !== false
  }

  isRequiredPreviousSiblingTypesPresent(types) {
    if (!this.hasChildren) {
      return false
    }

    return types.includes(this.getLastChild().type)
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      ...(this.children && {
        children: this.children.map((child) => child.toJSON()),
      }),
    }
  }
}
