import ModelTree from '/@models/v-tree'
import factory from '/@models/factory'
import { programElementTypes } from '/@/shared/constants'
import _get from 'lodash.get'
import { empty, uuid } from '/@shared/utils'

export default class ModelElement extends ModelTree {
  get isRoot() {
    return this.type === programElementTypes.root
  }

  get isElement() {
    return this.type === programElementTypes.element
  }

  get isWait() {
    return this.type === programElementTypes.wait
  }

  get isLoop() {
    return this.type === programElementTypes.loop
  }

  get isGroup() {
    return this.type === programElementTypes.group
  }

  get isMove() {
    return this.type === programElementTypes.move
  }

  get isSet() {
    return this.type === programElementTypes.set
  }

  get isCreate() {
    return this.type === programElementTypes.create
  }

  get isIf() {
    return this.type === programElementTypes.if
  }

  get isElseIf() {
    return this.type === programElementTypes.elseIf
  }

  get isElse() {
    return this.type === programElementTypes.else
  }

  get isStopLoop() {
    return this.type === programElementTypes.stopLoop
  }

  get isCall() {
    return this.type === programElementTypes.call
  }

  get hasVariable() {
    return !empty(this.variable)
  }

  get hasValue() {
    return !empty(this.value)
  }

  get isVariableDraggable() {
    return this.variableType !== variableTypes.PARAMETER && this.isVariableArraySet === false
  }

  get isValueDraggable() {
    return this.valueType === valueTypes.VARIABLE
  }

  get variableType() {
    return this.variable.startsWith('root/') //
      ? variableTypes.PARAMETER
      : variableTypes.SET_VARIABLE
  }

  get isVariableArraySet() {
    return this.variable.includes('[')
  }

  get valueType() {
    if (this.value.startsWith('root/')) {
      return valueTypes.PARAMETER
    }

    if (/^new (.*)(?=\(.*\))/.test(this.value)) {
      return valueTypes.PROGRAM
    }

    // todo fix this, can be different method
    return this.methodModel.variables.some((v) => v.title === this.value) //
      ? valueTypes.VARIABLE
      : valueTypes.VALUE
  }

  get programTitle() {
    const matches = this.value.match(/^new (.*)(?=\()/)

    if (matches && matches.length > 1) {
      return matches[1]
    }
  }

  get isCreateVariableType() {
    return this.variableType === variableTypes.CREATE_VARIABLE
  }

  get isValueValueType() {
    return this.valueType === valueTypes.VALUE
  }

  get isValueProgramType() {
    return this.valueType === valueTypes.PROGRAM
  }

  get isNewVariable() {
    const isArraySet = this.variable.includes('[')
    const isClassVariable = this.variable.startsWith('this.')
    const isVarInAncestorScope = this.isVariableInAncestorScope()

    if (isArraySet || isClassVariable) {
      return false
    }

    if (this.isPairedToGeometry) {
      const isGeometry = this.geometryModel.hasPoint((p) => p.title === this.variable)
      return !isVarInAncestorScope && !isGeometry
    }

    return !isVarInAncestorScope
  }

  isVariableInAncestorScope() {
    const allNodes = this.getRoot()
      .all()
      .filter((n) => n.isSet)

    const currentIndex = allNodes.findIndex((n) => n.id === this.id)
    return allNodes.findIndex((n) => n.variable === this.variable) < currentIndex
  }

  initVariables() {
    super.initVariables()

    this._type = programElementTypes.element
    this._programElement = null
    this._isGeometryRequired = false
  }

  mapChildren() {
    if (!this.canHaveChildren) {
      return
    }

    this.children = this.children.map((child) => {
      return factory.programElementFromType(child.type, child, this)
    })
  }

  clone () {
    const clone = factory.programElementFromType(this.type, this.toJSON())

    clone.walk((node) => {
      node.id = uuid()
      node.isActive = false
    })

    clone.parent = null
    return clone
  }

  toJS(loadedProgramModels) {
    let js = ''

    this.children.forEach((child) => {
      js += child.toJS(loadedProgramModels)
    })

    return js
  }

  toJSON() {
    const result = {}
    const excludeProperties = [
      'title',
      'icon',
      'isDeletable',
      'isDraggable',
      'hasMenu',
      'parent',
      'requiredPreviousSiblings',
      'requiredAncestors',
      'programElement',
    ]

    Object.keys(this)
      .map((key) => {
        return key.slice(1)
      })
      .filter((key) => {
        return !excludeProperties.includes(key)
      })
      .forEach((key) => {
        result[key] = this[key]
      })

    if (this.canHaveChildren) {
      result.children = this.children.map((child) => child.toJSON())
    }

    return result
  }
}

export const variableTypes = {
  SET_VARIABLE: 'set_variable',
  CREATE_VARIABLE: 'create_variable',
  PARAMETER: 'parameter',
}

export const valueTypes = {
  VARIABLE: 'variable',
  VALUE: 'value',
  PARAMETER: 'parameter',
  PROGRAM: 'program',
}
